@use 'variables';

/* PrimeNG */
@use '../../../node_modules/primeflex/primeflex.css';
@use '../../../node_modules/primeicons/primeicons.css';

@use 'layout/layout';

// components

@use 'components/table';

// gridstack
@use "gridstack/dist/gridstack.min.css";
//@import "gridstack/dist/gridstack-extra.min.css"; // if you use 2-11 column
.editing {
  &.grid-stack {
    background: #fafafa;

    .grid-stack-item-content {
      //text-align: center;
      background-color: #18bc9c;
      overflow: hidden !important;
    }
  }
}

.grid-stack {
  .grid-stack-item-content {
    overflow: hidden !important;
  }
}


.nowrap { white-space: nowrap }

.layout-main {
  transition: width 1s, margin-left 0.8s;
}

.layout-main.sidebar-closed {
  transition: width 0.6s;
  width: calc(100vw - 2.5rem); // Sidebar closed
}

.layout-main.sidebar-open {
  transition: width 0.6s;
  width: calc(100vw - 2.5rem); // Sidebar open
}

.layout-main.sidebar-locked {
  margin-left: 20rem;
  transition: width 0.6s, margin-left 0.6s;
  width: calc(100vw - 22rem);
}

.p-tabview {
  margin-right: 0.5rem;
}

.p-select-clear-icon {
  position: static !important;
}
p-select.ng-invalid > .p-select {
  border-color: #f87171;
}

.p-inputtext.ng-invalid, .p-inputtext.invalid {
  border-color: #f87171;
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #f87171;
}

.p-badge-danger {
  background-color: #f87171;
}

.input-group {
  label.ng-invalid {
    color: #f87171 !important;
  }

  .input-error-message {
    color: #f87171;
  }
}

  // description list
dl {
  display: block;
  margin: 0;
  padding: 0;
}

dt, dd {
  display: inline;
  margin: 0;
}

dt::after {
  content: ": ";
}

dt + dd::after {
  content: "";
  display: block;
}
