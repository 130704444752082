* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 0.860rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin-left: 5px;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.87);
}

.layout-wrapper {
  min-height: 100vh;
}
