$gutter: 1rem; // for primeflex grid system

$primaryColor: #f57c00;
$lightColor: #ffffff;
$errorColor: #df3434;
$secondaryColor: #383838;
$greenColor: #008000;

/* General */
$scale: 14px;                    /* main font size */
$borderRadius: 5px;             /* border radius of layout element e.g. card, sidebar */
$transitionDuration: .2s;        /* transition duration of layout elements e.g. sidebar, overlay menus */
