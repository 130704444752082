.layout-content-wrapper {
  display: flex;
  transition: all 0.3s ease;
  overflow-x: auto;
  background: #f6f6f6;

  .sidebar-placeholder {
    transition: all 0.3s ease;
  }

  .layout-main {
    flex: 1;
    transition: margin-left 0.5s;
    padding-left: 0;
  }

  .layout-main.expanded {
    padding-left: 0;
  }

  .layout-main.sidebar-locked {
    margin-left: 20rem;
    width: calc(100vw - 22rem);
  }
}

.dark-mode .layout-content-wrapper {
  background: transparent;
}

.active-menuitem {
  font-weight: bold;
  color: #F57C00FF !important;
}

.lock-button {
  margin-right: 0.5rem;
}

.bars-button {
  position: relative;
  margin-right: 0.5rem;
}
