.layout-main-container {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  //overflow-y: hidden;
  padding: 4rem 0.5rem 0 0;
}

.layout-main {
  flex: 1 1 auto;
  margin-left: 0.5rem;
}
