.layout-topbar {
  position: fixed;
  height: 4rem;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 1.5rem;
  background-color: var(--surface-card);
  //transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0,0,0,.02), 0 0 2px rgba(0,0,0,.05), 0 1px 4px rgba(0,0,0,.08);

  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    width: 100px;
    border-radius: 12px;

    img {
      height: 2.5rem;
      margin-right: .5rem;
    }

    //&:focus {
    //  @include focused();
    //}
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    //transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      //@include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  .layout-menu-button {
    margin-left: 2rem;
  }

  .layout-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;

    .layout-topbar-button {
      margin-left: 1rem;
    }
  }

  .layout-topbar-search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    .input {
      width: 800px;
    }
  }
}
