.p-datatable .p-datatable-header {
  padding: 0.75rem;
}

/*.p-datatable .p-datatable-table {
  table-layout: auto;
}*/

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.22rem 0.35rem;
}

.p-datatable .p-datatable-tbody > tr.height-expandable {
  height: 2.88rem;
}

th.p-sortable-column.p-highlight {
  color: #ffffff;
  font-weight: bold;
}

th.p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff !important;
}

.p-sortable-column-icon {
  transition: color 0.3s ease;
}

